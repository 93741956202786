/* 🔹 Asegurar que los iconos de PDF sean visibles */
.icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

/* 🔹 Ajustar el título de cada categoría */
h4 {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  margin-top: 20px;
}

/* 🔹 Espaciado entre tablas */
.mb-4 {
  margin-bottom: 30px;
}
