.uniform-card {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
}

.row.g-4 {
  margin-top: 20px;  /* Espaciado superior */
}

.uniform-card-content {
  margin: 15px 0;
  text-align: center;
}
